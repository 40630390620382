<script setup>
import {computed} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";

import {mdiEmailAlert, mdiCancel, mdiCheckCircleOutline, mdiAccountAlert} from "@mdi/js";

import {useDialogs} from '@/composables/useDialogs'

const {dialogTraineeEdit, dialogTraineeActivate} = useDialogs()

const props = defineProps({
    trainee: {
        type: Object,
        required: true,
    },
    icon: {
        type: [Boolean, Number],
        default: false,
    }
})

const statuses = [
    'Inactif.',
    'Actif.',
    'Profil incomplet.',
    'Adresse e-mail à activer.',
]

const text = computed(() => {
    return statuses[props.trainee.status]
})
const iconComponent = computed(() => {
    switch (props.trainee.status) {
        case 0:
            return mdiCancel
        case 1:
            return mdiCheckCircleOutline
        case 2:
            return mdiAccountAlert
        case 3:
            return mdiEmailAlert
        default:
            return null
    }
})
const iconClasses = computed(() => {

    const classes = 'vertical-align-middle inline-block '

    switch (props.trainee.status) {
        case 0:
        case 3:
            return classes + 'text-error-color'
        case 1:
            return classes + 'text-success-color'
        case 2:
            return classes + 'text-warning-color'
        default:
            return classes
    }
})

const action = () => {
    switch (props.trainee.status) {
        case 0:
        case 1:
            return dialogTraineeActivate(props.trainee)
        /*case 3:
            return dialogTraineeResendEmailActivation(props.trainee)*/
        case 2:
            return dialogTraineeEdit(props.trainee)
    }
}
</script>

<template>
    <component :is="props.trainee.status !== 3 ? 'a' : 'span'" href="" @click.prevent="action"><span
        :class="iconClasses" v-tooltip.bottom="text" class="inline-block vertical-align-middle mr-1"><svg-icon v-if="iconComponent" type="mdi" :path="iconComponent"
                                       :size="!icon || true === icon ? 16 : icon" /></span><span v-if="! icon"
                                                                                                 class="inline-block vertical-align-middle"> {{
            text
        }}</span></component>
</template>

<style scoped lang="scss">

</style>
