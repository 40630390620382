<script setup>
import {computed, ref} from "vue";

const props = defineProps({
  progress: {
    type: Number,
    default: 0
  },
  size: {
    type: Number,
    default: 24
  },
})

const $id = ref(Math.random().toString(36).slice(2))

const $color = computed(() => {
  if (100 === props.progress) {
    return '#86C847'
  } else {
    return '#F7AC15'
  }
})

const $color2 = computed(() => {
  if (100 === props.progress) {
    return '#B8DF92'
  } else {
    return '#FAC354'
  }
})

const height = computed(() => {
  return Math.round(47.1 * props.progress) / 100
})
const ypos = computed(() => {
  return 6.5 + 47.1 - height.value
})

</script>
<template>
  <div class="skill-progress-hive w-full">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="isolation:isolate"
         viewBox="0 0 70 60.667">
      <defs>
        <clipPath :id="`skillz-cut-off-${$id}-${progress}`">
          <rect x="0" :y="ypos" width="100%" :height="height"/>
        </clipPath>
      </defs>
      <g>
        <polygon points="52.5,60.667,17.5,60.667,0,30.333,17.5,0,52.5,0,70,30.333" fill="rgb(255,206,75)"/>
        <defs>
          <filter id="JNxUwzMCs1ftwlvJKCCipEBIlqGIuZBm" x="-200%" y="-200%" width="400%" height="400%"
                  filterUnits="objectBoundingBox" color-interpolation-filters="sRGB">
            <feOffset xmlns="http://www.w3.org/2000/svg" in="SourceGraphic" dx="0" dy="0"/>
            <feGaussianBlur xmlns="http://www.w3.org/2000/svg" stdDeviation="1.7174436251356113"
                            result="pf_100_offsetBlur"/>
            <feComposite xmlns="http://www.w3.org/2000/svg" in="SourceGraphic" in2="pf_100_offsetBlur"
                         result="pf_100_inverse" operator="out"/>
            <feFlood xmlns="http://www.w3.org/2000/svg" flood-color="#000000" flood-opacity="0.35"
                     result="pf_100_color"/>
            <feComposite xmlns="http://www.w3.org/2000/svg" in="pf_100_color" in2="pf_100_inverse" operator="in"
                         result="pf_100_shadow"/>
            <feComposite xmlns="http://www.w3.org/2000/svg" in="pf_100_shadow" in2="SourceGraphic" operator="over"/>
          </filter>
        </defs>
        <g filter="url(#JNxUwzMCs1ftwlvJKCCipEBIlqGIuZBm)">
          <polygon points="48.75,54.167,21.25,54.167,7.5,30.333,21.25,6.5,48.75,6.5,62.5,30.333"
                   fill="rgb(245,245,245)"/>
        </g>
        <g :clip-path="`url(#skillz-cut-off-${$id}-${progress})`">
          <polygon points="48.75,54.167,21.25,54.167,7.5,30.333,21.25,6.5,48.75,6.5,62.5,30.333"
                   :fill="$color"/>
          <path d=" M 17.067 39.914 L 12.375 30.143 L 24.458 10.369 L 36.081 10 L 26.403 14.341 L 17.191 29.636 L 17.067 39.914 Z "
                :fill="$color2"/>
        </g>
      </g>
    </svg>
  </div>
</template>
