<script setup>

import {required, isNafValid, NAFexists} from "@/lib/validators";
import {useVuelidate} from "@vuelidate/core";
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiCheckCircle} from "@mdi/js";
import FormFieldError from "@/components/FormFieldError.vue";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import {onBeforeMount, ref} from "vue";
import Button from "primevue/button";
import {useDictionaries} from "@/stores/dictionaries";
import Dropdown from "primevue/dropdown";

const dictionariesStore = useDictionaries()

const emit = defineEmits(['done', 'cancel'])

const props = defineProps({
    data: {
        type: [Object],
    },
})

const setErrors = ref()

const form = ref({
    ridetNumber: '',
    name: '',
    sign: '',
    acronym: '',
    legal_form: 'Personne Physique',
    naf_code: '',
    naf_label: '',
    city: '',
})


const rules = {
    ridetNumber: {required},
    name: {required},
    sign: {},
    acronym: {},
    naf_code: {required, isNafValid, NAFexists},
    city: {required},
}

const v$ = useVuelidate(rules, form, {$lazy: true})

const $city_options = ref([])
const $city_options_loading = ref(true)

const load_cities = () => {
    dictionariesStore.citiesDropdown().then((json) => {
        $city_options_loading.value = false
        $city_options.value = json
    })
}

const clearErrors = () => {
    return setErrors.value = null
}
const submit = async () => {
    clearErrors()
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) return

    const ridetNumber = form.value.ridetNumber.replaceAll(' ', '')
    const matched = ridetNumber.match(/^([0-9]{7})\.([0-9]{3})$/)

    const data = {
        enseigne: form.value.acronym,
        rid7: matched[1],
        denomination: form.value.name,
        code_ape: form.value.naf_code,
        sigle: form.value.sign,
        libelle_naf: form.value.naf_label,
        libelle_commune: form.value.city,
        ndegetablissement: matched[2],
        libelle_formjur: form.value.legal_form,
    }

    emit('done', data)
}

onBeforeMount(() => {
    load_cities()
    if (props.data) {
        form.value = {
            ridetNumber: props.data.rid7 ? `${props.data.rid7}.${props.data.ndegetablissement}` : '',
            name: props.data.denomination || '',
            sign: props.data.sigle || '',
            acronym: props.data.enseigne || '',
            legal_form: 'Personne Physique',
            naf_code: props.data.code_ape || '',
            naf_label: props.data.libelle_naf || '',
            city: props.data.libelle_commune || '',
        }
        v$.value.$validate()
    }
})
</script>

<template>
    <p class="mt-0">Merci de renseigner les informations de l'entreprise individuelle.</p>
    <form @submit.prevent="submit" class="flex flex-column gap-4 mt-6">
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <InputMask id="ridetFormFreelancerridetNumber" v-model="form.ridetNumber"
                               mask="9 999 999.999"
                               palceholder="1 234 567.123"
                               class=""
                               autocomplete="organization-id"
                               @blur="v$.ridetNumber.$touch"
                               @focus="clearErrors"
                               :class="{ 'p-invalid': v$.ridetNumber.$error }"
                    />
                    <label for="ridetFormFreelancerridetNumber">Numéro de RIDET</label>
                </span>
            </span>
            <FormFieldError :errors="v$.ridetNumber.$error ? v$.ridetNumber.$errors : [] "/>
        </div>
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <InputText id="ridetFormFreelancerlegalForm" v-model="form.legal_form" class="" :disabled="true"/>
                    <label for="ridetFormFreelancerlegalForm">Form Juridique</label>
                </span>
            </span>
        </div>
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <InputText id="ridetFormFreelancername" v-model="form.name"
                               class=""
                               autocomplete="organization-title"
                               @blur="v$.name.$touch"
                               @focus="clearErrors"
                               :class="{ 'p-invalid': v$.name.$error }"
                    />
                    <label for="ridetFormFreelancername">Désignation</label>
                </span>
            </span>
            <FormFieldError :errors="v$.name.$error ? v$.name.$errors : [] "/>
        </div>
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <InputText id="ridetFormFreelancersign" v-model="form.sign"
                               class=""
                               autocomplete="organization-title"
                               @blur="v$.sign.$touch"
                               @focus="clearErrors"
                               :class="{ 'p-invalid': v$.sign.$error }"
                    />
                    <label for="ridetFormFreelancersign">Sigle, Nom commercial</label>
                </span>
            </span>
            <FormFieldError :errors="v$.sign.$error ? v$.sign.$errors : [] "/>
        </div>
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <InputText id="ridetFormFreelanceracronym" v-model="form.acronym"
                               class=""
                               autocomplete="organization-title"
                               @blur="v$.acronym.$touch"
                               @focus="clearErrors"
                               :class="{ 'p-invalid': v$.acronym.$error }"
                    />
                    <label for="ridetFormFreelanceracronym">Enseigne</label>
                </span>
            </span>
            <FormFieldError :errors="v$.acronym.$error ? v$.acronym.$errors : [] "/>
        </div>
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <InputMask id="ridetFormFreelancernaf_code" v-model="form.naf_code"
                               mask="9999a"
                               palceholder="1234A"
                               class=""
                               autocomplete="organization-id"
                               @blur="v$.naf_code.$touch"
                               @focus="clearErrors"
                               :class="{ 'p-invalid': v$.naf_code.$error }"
                    />
                    <label for="ridetFormFreelancernaf_code">Code APE / NAF</label>
                </span>
            </span>
            <div class="mt-2 flex align-items-center gap-1" v-if="!v$.naf_code.$error && form.naf_label"><svg-icon type="mdi" :path="mdiCheckCircle" :size="16" />  {{form.naf_label}}</div>
            <FormFieldError :errors="v$.naf_code.$error ? v$.naf_code.$errors : [] " :multiple="false"/>
        </div>
        <div>
            <span class="p-inputgroup">
                <span class="p-float-label p-inputgroup">
                    <Dropdown id="ridetFormFreelancercity" v-model="form.city" inputId="city" :options="$city_options"
                              option-label="label"
                              option-value="value"
                              class="w-full" :loading="$city_options_loading" filter
                              :class="{ 'p-invalid': v$.city.$error }"/>
                    <label for="ridetFormFreelancercity" class="p-label">Ville</label>
                </span>
            </span>
            <FormFieldError :errors="v$.city.$error ? v$.city.$errors : [] "/>
        </div>

        <div class="flex justify-content-between align-items-center mt-0">
            <Button
                label="Annuler"
                class="p-0"
                link
                @click="emit('cancel')"
                severity="secondary"
            >

            </Button>

            <Button type="submit" icon="mdi mdi-login"
                    label="Enregistrer"/>
        </div>
    </form>
</template>

<style scoped lang="scss">

</style>
