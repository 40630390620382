<script setup>

import {QrcodeStream} from 'vue-qrcode-reader'
import {computed, inject, ref} from "vue";

// import Button from 'primevue/button'
import ProgressSpinner from "primevue/progressspinner"

import SvgIcon from '@jamescoyle/vue-icon'
import {mdiCheckCircleOutline, mdiCloseCircleOutline} from '@mdi/js'

const dialogRef = inject('dialogRef', null)

const isValid = ref()
const paused = ref(false)
const result = ref(null)

const validationPending = computed(() => {
  return isValid.value === undefined && paused.value
})

const validationSuccess = computed(() => {
  return isValid.value === true
})

const validationFailure = computed(() => {
  return isValid.value === false
})

const resetValidationState = () => {
  isValid.value = undefined
}

const onError = console.error

const onDetect = async ([firstDetectedCode]) => {
  result.value = firstDetectedCode.rawValue
  paused.value = true

  const baseUrl = new URL(`/p/`, location.href).href

  // pretend it's taking really long
  let valid = result.value.startsWith(baseUrl)

  if (valid) {
    const token = result.value.substring(baseUrl.length)
    valid = token && token.match(/^[A-z0-9]{6}$/i)
  }

  if (valid) {
      isValid.value = true
      await timeout(2000)

      dialogRef.value.close({url: result.value})

  } else {
    isValid.value = false
  }

  if (!isValid.value) {
    // some more delay, so users have time to read the message
    await timeout(2000)
    paused.value = false
  }

}

const timeout = (ms) => {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms)
  })
}

const paintOutline = (detectedCodes, ctx) => {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = '#B12036'

    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const {x, y} of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
  }
}

</script>

<template>
  <div class="flex justify-content-center w-full">
    <div class="max-w-30rem">
      <qrcode-stream :track="paintOutline" :paused="paused" @detect="onDetect" @error="onError"
                     @camera-on="resetValidationState">
        <div v-if="validationSuccess" class="validation-success">
          <svg-icon type="mdi" :path="mdiCheckCircleOutline" :size="128" class="block"></svg-icon>
        </div>
        <div v-if="validationFailure" class="validation-failure">
          <svg-icon type="mdi" :path="mdiCloseCircleOutline" :size="128" class="block"></svg-icon>
        </div>
        <div v-if="validationPending" class="validation-pending">
          <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
        </div>
      </qrcode-stream>
    </div>
  </div>
</template>

<style scoped lang="scss">
.validation-success, .validation-failure, .validation-pending {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.validation-success {
  color: $quaternaryColor;
}

.validation-failure {
  color: $errorColor;
}
</style>
