<script setup>
import {computed} from "vue";

const props = defineProps({
    ridet: {
        type: Object,
        required: true,
    }
})

const item = computed(() => {
    const $ridet = props.ridet.fields || props.ridet

    if (props.ridet.fields) {
        return {
            rid7: $ridet.rid7,
            rideta: $ridet.ndegetablissement,
            name: $ridet.denomination,
            sign: $ridet.enseigne,
            acronym: $ridet.sigle,
            naf_label: $ridet.libelle_naf,
            legal_form: $ridet.libelle_formjur,
        }
    }

    return {
        rid7: $ridet.$$('rid7'),
        rideta: $ridet.$$('rideta'),
        name: $ridet.$$('ridet_name'),
        sign: $ridet.$$('ridet_sign'),
        acronym: $ridet.$$('ridet_acronym'),
        naf_label: $ridet.$$('libelle_naf'),
        legal_form: $ridet.$$('libelle_formjur'),
    }
})
</script>
<template>
    <div class="w-full flex flex-column gap-2">
        <div>
            <strong>{{ item.rid7 }}.{{ item.rideta }} <span v-if="item.acronym">-
                {{ item.acronym }}</span></strong><br>
            {{ item.legal_form }}<br>
            {{ item.naf_label }}
        </div>
        <div v-if="item.sign && item.name && item.name !== item.sign">
            {{ item.name }}
        </div>
        <div>
            <strong>{{ item.sign || item.name }}</strong>
        </div>
    </div>
</template>
