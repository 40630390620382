<script setup>
import {ref, inject, onBeforeMount, computed} from "vue";

import Button from "primevue/button";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";

import FormFieldError from "@/components/FormFieldError.vue";

import {useToast} from 'primevue/usetoast';
import {useDate} from '@/composables/useDate';

/* Validation */
import ValidationErrors from "@/components/ValidationErrors.vue";

import {useVuelidate} from '@vuelidate/core'
import {serverSide} from '@/lib/validators'
//import {useTrainees} from "@/stores/trainees";
/* End Validation */

const dialogRef = inject('dialogRef', null)

const props = defineProps({
  trainee: {
    type: Object,
  },
  item: {
    type: Object,
  },

})

const emit = defineEmits(['updated'])

const date = useDate()
const toast = useToast();

const $item = ref(null)

const $loading = ref(true)
const $trainee = ref(null)

const form = ref({
  name: '',
  from: '',
  to: '',
})

const processing = ref(false)
const setErrors = ref()

/**
 * Computed
 */
const errors = computed(() => {
  return setErrors.value ? setErrors.value.errors : null
})

const errorMessage = computed(() => {
  //if (setErrors.value && undefined !== setErrors.value.message) return setErrors.value.message
  if (setErrors.value?.errors.general && undefined !== setErrors.value?.errors.general) return setErrors.value?.errors.general
  if (v$.value.$invalid) return "Une ou plusieurs erreurs sont présentes dans le formulaire.\n" +
      "Merci de rectifier votre saisie."
  return null
})

const canSubmit = computed(() => {
  return v$.value.$invalid
})

const maxDateFrom = ref(date())
const maxDateTo = ref(date())
const minDateTo = computed(() => form.value.from ? date(form.value.from) : date())

const closeDialog = () => {
  if (dialogRef.value) dialogRef.value.close($trainee)
}

const submit = async () => {
  clearErrors()
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return

  const data = {
    ...form.value,
    user_id: $trainee.value.id,
    to: date(form.value.to).isValid() ? date(form.value.to).format('YYYY-MM-DD') : '',
    from: date(form.value.from).isValid() ? date(form.value.from).format('YYYY-MM-DD') : '',
  }
  if ($item.value) {
    data.id = $item.value.id
  }

  const updatedItem = await $trainee.value.updateJob(data, processing, setErrors)

  if (updatedItem) {
    processing.value = true

    emit('updated', $trainee, updatedItem)

    processing.value = false

    toast.add({
      severity: 'success',
      summary: 'Effectué.',
      detail: 'Emploi ou stage ' + ($item.value ? 'modifié' : 'ajouté') + ' avec succès',
      life: 3000
    });
    closeDialog()
  }

  await v$.value.$validate()
}

/**
 * Form validation
 */
const rules = {
  name: {serverSide: serverSide({field: 'name', errors})},
  organization: {serverSide: serverSide({field: 'organization', errors})},
  from: {/*required,*/ serverSide: serverSide({field: 'from', errors})},
  to: {/*required,*/ serverSide: serverSide({field: 'to', errors})},
}

const v$ = useVuelidate(rules, form, {$lazy: true})

const clearErrors = () => {
  return setErrors.value = null
}
/* End form validation */

onBeforeMount(async () => {
  if (dialogRef.value.data) {
    $item.value = dialogRef.value.data.item
    $trainee.value = dialogRef.value.data.trainee
  } else {
    $item.value = props.item
    $trainee.value = props.trainee
  }
  if ($item.value) {
    form.value = {
      name: $item.value.name,
      organization: $item.value.organization,
      to: date($item.value.to).toDate(),
      from: date($item.value.from).toDate(),
    }
  }

  $loading.value = false
})
</script>
<template>
  <div v-if="$loading" class="text-center">
    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
  </div>
  <form @submit.prevent="submit" class="flex flex-column gap-4" v-else>
    <div>
      <label for="name" class="p-label">Emploi occupé</label>
      <div class="p-inputgroup">
        <InputText
            id="name"
            v-model="form.name"
            type="text"
            class=""
            autocomplete="family-name"
            @blur="v$.name.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.name.$error }"/>
      </div>
      <FormFieldError :errors="v$.name.$error ? v$.name.$errors : [] "/>
    </div>
    <div>
      <label for="organization" class="p-label">Organisme</label>
      <div class="p-inputgroup">
        <InputText
            id="organization"
            v-model="form.organization"
            type="text"
            class=""
            autocomplete="family-organization"
            @blur="v$.organization.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.organization.$error }"/>
      </div>
      <FormFieldError :errors="v$.organization.$error ? v$.organization.$errors : [] "/>
    </div>

    <div>

      <label for="from" class="p-label">Du</label>

      <div class="p-inputgroup">
        <Calendar
            inputId="from"
            v-model="form.from"
            autocomplete="bday"
            show-icon
            touchUI
            manualInput
            :max-date="maxDateFrom.toDate()"
            @blur="v$.from.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.from.$error }"
        />
      </div>
      <FormFieldError :errors="v$.from.$error ? v$.from.$errors : [] "/>
    </div>

    <div>
      <label for="to" class="p-label">Au</label>
      <div class="p-inputgroup">
        <Calendar
            inputId="to"
            v-model="form.to"
            autocomplete="bday"
            show-icon
            touchUI
            manualInput
            :max-date="maxDateTo.toDate()"
            :min-date="minDateTo.toDate()"
            @blur="v$.to.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.to.$error }"
        />
      </div>
      <FormFieldError :errors="v$.to.$error ? v$.to.$errors : [] "/>
    </div>

    <ValidationErrors class="mb-0 w-full" :error="errorMessage"/>

    <div class="flex justify-content-between align-items-center mt-0">
      <Button
          label="Annuler"
          class="p-0"
          link
          @click="closeDialog"
          severity="secondary"
      >

      </Button>

      <Button type="submit" icon="mdi mdi-login" :loading="processing" :disabled="canSubmit"
              label="Enregistrer"/>
    </div>

  </form>
</template>
