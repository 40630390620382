<script setup>
import {inject, onBeforeMount, ref} from "vue";
import CircleTag from "@/components/CircleTag.vue";

const dialogRef = inject('dialogRef', null)

const skill = ref(null)

onBeforeMount(async () => {
    skill.value = dialogRef.value.data.skill
})
</script>
<template>
    <div>
        <div class="font-family-tradegothic font-bold p-text-tertiary-color text-2xl flex gap-2">
            <CircleTag :value="'C' + (skill.order+1)" class="white" :size="1.2"></CircleTag>
            <span class="flex-grow-1">{{ skill.name }}</span>
        </div>
    </div>
</template>
