<script setup>

import {computed, inject, ref} from "vue";
import Avatar from "primevue/avatar";
import Tag from "primevue/tag";
import Button from "primevue/button";
import {useToast} from "primevue/usetoast";
import {useAuth} from "@/stores/auth";

import SvgIcon from '@jamescoyle/vue-icon'
import {mdiCancel} from "@mdi/js";
import {useConfirm} from "primevue/useconfirm";

const confirm = useConfirm();

const toast = useToast()
const dialogRef = inject('dialogRef', null)
const {indicator, criteria, skill, trainee} = dialogRef.value.data
const $processing = ref(false)
const $invalidateProcessing = ref(false)

const closeDialog = () => {
    if (dialogRef.value) dialogRef.value.close()
}

const adminValidated = computed(() => {
    return indicator.validation && indicator.validation.admin_validated_at
})

const organizationValidated = computed(() => {
    return indicator.validation && indicator.validation.organization_validated_at
})

console.log(indicator);

const criteriaCode = computed(() => {
    return 'C' + (skill.order + 1) + '-' + (criteria.order + 1)
})

const adminValidatedIconStyles = computed(() => {
    const styles = {
        color: 'var(--gray-300)'
    }

    if (adminValidated.value) {
        styles.color = 'var(--green-500)'
    }
    return styles
})

const organizationValidatedIconStyles = computed(() => {
    const styles = {
        color: 'var(--gray-300)'
    }

    if (organizationValidated.value) {
        styles.color = 'var(--green-500)'
    }
    return styles
})

const canSubmit = computed(() => {
    if (useAuth().authUser.hasTrainee(trainee) && !useAuth().authUser.isSuperAdmin) {
        return !organizationValidated.value
    }
    return !adminValidated.value
})

const validateSubmit = async () => {
    $processing.value = true
    const response = await trainee.validateIndicator(indicator)
    $processing.value = false

    if (response) {
        toast.add({
            severity: 'success',
            summary: 'Enregistré',
            detail: "Indicateur validé.",
            life: 5000
        })
        closeDialog()
    } else {
        toast.add({
            severity: 'error',
            summary: 'Erreur',
            detail: "Impossible de valider l'indicateur.",
            life: 5000
        })
    }
    return true
}

const invalidateSubmit = async (isOrganizationValidation = true) => {
    confirm.require({
        header: "Retirer une validation.",
        message: 'Voulez vous vraiment retirer cette validation ? Cette action est irréversible.',
        icon: 'mdi mdi-alert-outline',
        acceptClass: 'p-button-danger',
        accept: async () => {
            $invalidateProcessing.value = true
            const response = await trainee.invalidateIndicator(indicator, isOrganizationValidation ? 1 : 0)
            $invalidateProcessing.value = false

            if (response) {
                toast.add({
                    severity: 'success',
                    summary: 'Enregistré',
                    detail: "Validation de l'indicateur retirée.",
                    life: 5000
                })
                closeDialog()
            } else {
                toast.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: "Impossible de retirer la validation de l'indicateur.",
                    life: 5000
                })
            }
            return true
        }
    })

}


</script>
<template>
    <div class="flex flex-column gap-4">
        <div class="flex align-items-center gap-4">
            <Avatar ref="avatar" :image="trainee.avatarUrl" class="mr-2 flex-shrink-0" size="large" shape="circle"/>
            <div class="flex-grow-1">
                <div class="font-family-tradegothic font-bold">{{ trainee.$data.lastname }} {{
                        trainee.$data.firstname
                    }}
                </div>
            </div>
        </div>

        <div class="data-list-item p-3 flex flex-column gap-2">
            <div>
                <Tag :value="criteriaCode" class="font-family-tertiary text-base skill"/>
                <span class="font-family-secondary font-bold ml-2">{{ criteria.name }}</span>
            </div>
            <div>
        <span
            class="font-family-secondary font-bold p-text-tertiary-color text-xs mr-2 ">Indicateur {{
                indicator.order + 1
            }}</span>
                <span>{{ indicator.name }}</span>
            </div>
        </div>

        <div class="data-list-item p-3 flex flex-column gap-2">
            <h4 class="mt-0 mb-2">Statut de l'indicateur</h4>
            <div class="flex align-items-center">
                <i class="mdi mdi-check-circle-outline mr-1"
                   :style="adminValidatedIconStyles"/>
                <span v-if="adminValidated" class="flex-grow-1 flex justify-content-between align-items-center"><span>Validé par le SPOT</span>
            <Button severity="danger" v-if="trainee.canInvalidateIndicator(indicator, false)" text
                    class="p-button-icon p-1 overflow-visible" size="small" label="Retirer"
                    :loading="$invalidateProcessing"
                    @click="(event)=> invalidateSubmit(false, event)">
              <template v-slot:icon>
                <SvgIcon type="mdi" size="12" :path="mdiCancel" class=" flex-shrink-0 mr-1"></SvgIcon>
              </template>
              </Button></span>
                <span v-else>En attente de validation SPOT</span>
            </div>
            <div class="flex align-items-center">
                <i class="mdi mdi-check-circle-outline mr-1" :style="organizationValidatedIconStyles"/>
                <div v-if="organizationValidated" class="flex-grow-1 flex justify-content-between align-items-center">
                    <span>Validé par un employeur</span>
                    <Button severity="danger" v-if="trainee.canInvalidateIndicator(indicator, true)" text
                            class="p-button-icon p-1 overflow-visible" size="small" label="Retirer"
                            :loading="$invalidateProcessing"
                            @click="(event)=> invalidateSubmit(true, event)">
                        <template v-slot:icon>
                            <SvgIcon type="mdi" size="12" :path="mdiCancel" class="mr-1 flex-shrink-0"></SvgIcon>
                        </template>
                    </Button>
                </div>
                <span v-else>En attente de validation employeur</span>
            </div>
        </div>
        <div v-if="trainee.canValidateIndicator">
            <p>En tant qu'administrateur vous pouvez apporter votre validation.</p>
            <p>Souhaitez vous valider cette indicateur ?</p>
        </div>
        <div class="flex justify-content-between align-items-center mt-0">
            <Button
                label="Fermer"
                class="p-0"
                link
                @click="closeDialog"
                severity="secondary"
            >

            </Button>

            <Button type="button" icon="mdi mdi-check-circle-outline" :loading="$processing" :disabled="! canSubmit"
                    @click="validateSubmit"
                    label="Valider l'indicateur" v-if="trainee.canValidateIndicator"/>
        </div>
    </div>
</template>
