<script setup>

import {ref} from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";

import Avatar from 'primevue/avatar';

import SvgIcon from "@jamescoyle/vue-icon";
import {mdiShieldCheckOutline} from "@mdi/js";

import {useDialogs} from "@/composables/useDialogs";
import {useRouter} from "vue-router";
import {useUsers} from "@/stores/users";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";

const {dialogUserEdit, dialogUserView/*, dialogOrganizationView*/} = useDialogs()

const $router = useRouter()
const toast = useToast()
const confirm = useConfirm()

const emit = defineEmits(['deleted'])

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  context: {
    type: String,
    default: 'default',
  }
})

const item = ref(props.item)
const menu = ref()
const menuItems = (/*record*/) => {
  const items = []

  if (item.value.canEdit) {
    items.push({
      label: 'Modifier',
      icon: 'mdi mdi-pencil',
      iconSingle: 'mdi mdi-pencil-circle-outline',
      command: () => dialogUserEdit(item.value)
    })
  }
  if (item.value.canDestroy) {
    items.push({
      label: 'Supprimer',
      icon: 'mdi mdi-delete',
      iconSingle: 'mdi mdi-pencil-delete-outline',
      class: 'p-text-secondary-color',
      iconSeverity: 'danger',
      command: () => {
        confirm.require({
          header: "Suppression d'un compte utilisateur",
          message: 'Voulez vous vraiment supprimer ce compte utilisateur ? Cette action est irréversible.',
          icon: 'mdi mdi-alert-outline',
          acceptClass: 'p-button-danger',
          accept: async () => {
            const done = await useUsers().destroy(item.value)
            if (true === done) {
              toast.add({
                severity: 'success',
                summary: 'Suppression effectuée',
                detail: "L'utilisateur à été supprimé avec succès.",
                life: 3000
              });
              emit('deleted')
            } else {
              toast.add({
                severity: 'error',
                summary: 'Suppression impossible',
                detail: "La suppression à échouée.",
                life: 30000
              });
            }
          }
        });
      }
    })
  }
  return items
}

const select = () => {
  if (props.context === 'users-list') {
    $router.push({name: 'users', params: {id: props.item.id}})
  } else {
    dialogUserView(props.item)
  }
}

const toggleMenu = (event) => {
  menu.value.toggle(event);
}

</script>
<template>
  <router-link :to="{name: 'users', params: {id: item.id}}" v-if="item" custom v-slot="{ href }">
    <a :href="href" @click.prevent="select" class="no-underline">
      <div class="item mb-4 p-3 cursor-pointer flex align-items-center gap-2 sm:gap-4 shadow-1">
        <Avatar ref="avatar" :image="item.avatarUrl" class="mr-2 flex-shrink-0" size="xlarge" shape="circle"/>
        <div class="flex-grow-1" style="min-width: 0px;">
          <span class="font-family-tradegothic font-bold">{{ item.$data.lastname }} {{ item.$data.firstname }}</span>
          <div
              style="color: var(--lt-color-gray-500); text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
              class="text-sm" v-if="item.$data.organization">
<!--            <router-link :to="{name: 'organizations', params: {id: item.organization.id}}" custom
                         v-slot="{ href }">
              <a :href="href" @click.prevent.stop="dialogOrganizationView(item.organization)">-->
                <span v-if="item.$data.organization.certified"
                      class="inline-block vertical-align-text-top text-success-color mr-1"><svg-icon
                    type="mdi" :path="mdiShieldCheckOutline"
                    :size="16" class="block"/></span>{{ item.$data.organization.display_name }}<!--</a></router-link>-->
            <span
                v-if="item.meta('position')"><br>
                {{ item.meta('position') }}</span>
          </div>
        </div>
        <div class="flex-grow-0 flex flex-nowrap align-items-center gap-2">
          <i class="mdi mdi-email-alert-outline" style="font-size: 2rem; color: var(--secondary-color)"
             v-if="!item.isEmailVerified"
             v-tooltip.left="'Cet utilisateur n\'a pas vérifié son addresse E-mail.'"/>

          <i class="mdi mdi-cancel" style="font-size: 2rem; color: var(--secondary-color)"
             v-if="!item.isActive"
             v-tooltip.left="'Cet utilisateur est inactif.'"/>

          <i class="mdi mdi-crown-circle-outline" style="font-size: 2rem; color: var(--primary-color)"
             v-if="item.isSuperAdmin"
             v-tooltip.left="'Cet utilisateur est un super-administrateur.'"/>

          <span v-else class="white-space-nowrap">
              <i class="mdi mdi-star-circle-outline" style="font-size: 2rem; color: var(--primary-color)"
                 v-if="item.isAdmin"
                 v-tooltip.left="'Cet utilisateur est un administrateur.'"/>
              <span v-else class="white-space-nowrap">
                  <i class="mdi mdi-shield-star-outline" style="font-size: 2rem; color: var(--green-500)"
                     v-if="item.isProfessionalAdmin"
                     v-tooltip.left="'Cet utilisateur est administrateur de son organisme.'"/>
                  <i class="mdi mdi-shield-check-outline" style="font-size: 2rem; color: var(--green-500)"
                     v-if="item.isCertified"
                     v-tooltip.left="'Cet utilisateur est certifié.'"/>
            </span>
      </span>
          <div v-if="menuItems().length > 1">
            <Button icon="mdi mdi-dots-horizontal-circle-outline " class="p-button-xxl"
                    iconClass="text-3xl flex"
                    severity="info"
                    @click.stop.prevent="toggleMenu" rounded text/>

            <Menu ref="menu" id="overlay_menu" :model="menuItems()" :popup="true"/>
          </div>
          <div v-else-if="menuItems().length">
            <Button :icon="menuItems()[0].iconSingle || menuItems()[0].icon"
                    :class="'p-button-xxl ' + menuItems()[0].class"
                    iconClass="text-3xl flex"
                    :severity="menuItems()[0].iconSeverity || 'info'"
                    @click.stop.prevent="menuItems()[0].command" rounded text/>
          </div>
        </div>
      </div>
    </a>
  </router-link>
</template>
