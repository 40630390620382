<script setup>

import {computed} from "vue";
import {useRouter} from "vue-router";
import Navigation from "@/components/Navigation.vue";
import {useAuth} from "@/stores/auth";

const $router = useRouter();

const items = computed(() => {
    const items = {
        about: {
            label: 'À propos',
            icon: 'mdi mdi-information-outline',
            to: {name: 'about'},
        },
        register: {
            label: "S'enregistrer",
            to: {name: 'register'},
            icon: 'mdi mdi-file-document-edit-outline',
        },
    }

    if (useAuth().authenticated && useAuth().authUser) {
        items.logout = {
            label: "Se déconnecter",
            command: () => {
                useAuth().logout()
            },
            icon: 'mdi mdi-logout',
            disabled: function () {
                if (useAuth().authenticated && useAuth().authUser) {
                    return false
                }
                return true
            }
        }

    } else {
        items.login = {
            label: "Se connecter",
            to: {name: 'login'},
            icon: 'mdi mdi-login',
            disabled: function () {
                if (useAuth().authenticated && useAuth().authUser) {
                    return true
                }
                return false
            }
        }
    }
    return items
})

const aboutItems = {
    login: {
        label: 'Retour',
        icon: 'mdi mdi-arrow-left-circle',
        to: {name: 'login'},
        back: false,
    },
    about: {
        label: 'À propos',
        icon: 'mdi mdi-information-outline',
        to: {name: 'about'},
    },
    privacy: {
        label: 'Politique de confidentialité',
        icon: 'mdi mdi-shield-account-outline',
        to: {name: 'privacy'},
    },
    terms: {
        label: "Conditions d'utilisation",
        icon: 'mdi mdi-file-sign',
        to: {name: 'terms'},
    },

}

const menuItems = computed(() => {
    if ($router.currentRoute.value.matched[0] && ['about', 'privacy', 'terms'].includes($router.currentRoute.value.matched[0].name)) {
        return aboutItems
    }

    return items.value
})

</script>

<template>
    <Navigation :items="menuItems"></Navigation>
</template>
