<script setup>

import {ref, inject, onBeforeMount} from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";

import Avatar from 'primevue/avatar';

import TraineeEdit from '@/components/Trainee/Edit.vue'
import SkillProgress from "@/components/Trainee/SkillProgress.vue";
import TraineeStatus from "@/components/Trainee/Status.vue";

import {useDialog} from 'primevue/usedialog';
import Chip from "primevue/chip";
import {useRouter} from "vue-router";
import {useTrainees} from "@/stores/trainees";
import {useToast} from 'primevue/usetoast';
import {useConfirm} from "primevue/useconfirm";
import {useAuth} from "@/stores/auth";

const dynamicDialogDefaults = inject('dynamicDialogDefaults')

const dialog = useDialog()
const router = useRouter()
const toast = useToast();
const confirm = useConfirm()

const emit = defineEmits(['deleted'])

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
})

const item = ref(props.item)
const menu = ref()
const $dialogView = ref()

const $skills = ref()
const menuItems = (/*record*/) => {
  const items = []

  items.push({
    label: 'C.V.',
    icon: 'mdi mdi-card-account-details-outline',
    command: () => {
      router.push({name: 'trainee-cv', params: {id: item.value.id}})
    }
  })
  items.push({
    label: 'Compétences',
    icon: 'mdi mdi-clipboard-check-multiple-outline',
    command: () => {
      router.push({name: 'trainee-skills', params: {id: item.value.id}})
    }
  })
  if (item.value.canEdit) {
    items.push({
      label: 'Employeurs',
      icon: 'mdi mdi-domain',
      command: () => {
        router.push({name: 'trainee-employers', params: {id: item.value.id}})
      }
    })
  }

  const moreItem = []
  if (item.value.canEdit) {
    moreItem.push({
      label: 'Modifier',
      icon: 'mdi mdi-pencil',
      iconSingle: 'mdi mdi-pencil-circle-outline',
      command: () => dialogEdit()
    })
  }

  if(item.value.canRemove && useAuth().authUser.hasRole('professional')) {
    moreItem.push({
      label: "Révoquer",
      icon: 'mdi mdi-close-octagon-outline',
      class: 'p-text-secondary-color',
      iconSeverity: 'danger',
      command: () => {
        confirm.require({
          header: "Révocation d'un accès à un passeport",
          message: "Voulez vous apprêtez à supprimer l'accès au passeport d'un stagiaire. Vous n'y aurez plus accès. Voulez-vous vraiment le révoquer ?",
          icon: 'mdi mdi-alert-outline',
          acceptClass: 'p-button-danger',
          accept: async () => {

            const done = await useAuth().authUser.organization.removeTrainee(item.value)

            if (true === done) {
              emit('deleted')
              toast.add({
                severity: 'info',
                summary: 'Révocation effectuée',
                detail: "Vous n'avez plus accès à ce passeport B.E.E.",
                life: 3000
              })
            } else {
              toast.add({
                severity: 'error',
                summary: 'Révocation impossible',
                detail: "La révocation à échouée." + (done.message ? ` (${done.message})` : ''),
                life: 30000
              });
            }

            //removeLoading.value = false
          },
          reject: () => {
            //removeLoading.value = false
          },
        });
      }
    })
  }

  if (item.value.canDestroy) {
    moreItem.push({
      label: 'Supprimer',
      icon: 'mdi mdi-delete',
      iconSingle: 'mdi mdi-delete-circle-outline',
      class: 'p-text-secondary-color',
      command: () => {
        confirm.require({
          header: "Suppression d'un compte stagiaire",
          message: 'Voulez vous vraiment supprimer ce compte stagiaire ? Cette action est irréversible.',
          icon: 'mdi mdi-alert-outline',
          acceptClass: 'p-button-danger',
          iconSeverity: 'danger',
          accept: async () => {
            const done = await useTrainees().destroy(item.value)
            if (true === done) {
              toast.add({
                severity: 'success',
                summary: 'Suppression effectuée',
                detail: "Le stagiaire à été supprimé avec succès.",
                life: 3000
              });
              item.value=null
              emit('deleted')
            } else {
              toast.add({
                severity: 'error',
                summary: 'Suppression impossible',
                detail: "La suppression à échouée.",
                life: 30000
              });
            }
          }
        });
      }
    })
  }


  if (items.length && moreItem.length) {
    items.push({separator: true})
  }

  return [...items, ...moreItem]
}

const toggleMenu = (event) => {
  menu.value.toggle(event);
}

/*const select = (event) => {
    console.log(event)
    if (!event.target.classList.contains('path') && !event.target.classList.contains('p-button') && !event.target.classList.contains('p-button-icon')) {
        router.push({name: 'trainee', params: {id: item.value.id}})
    }
}*/

/*const dialogView = () => {
    $dialogView.value = dialog.open(TraineeView, {
        ...dynamicDialogDefaults,
        props: {
            ...dynamicDialogDefaults.props,
            header: 'Organisme',
            dismissableMask: true,
        },
        templates: {
            header: TraineeViewHeader,
        },
        data: {
            item,
        }
    });
}*/

const dialogEdit = () => {
  $dialogView.value = dialog.open(TraineeEdit, {
    ...dynamicDialogDefaults,
    props: {
      ...dynamicDialogDefaults.props,
      header: props.item.hasData ? "Modifier un utilisateur" : "Ajouter un utilisateur"
    },
    data: {
      item,
    }
  });
}

onBeforeMount(async () => {
  $skills.value = await props.item.skills
})
</script>
<template>
  <router-link :to="{name: 'trainee', params: {id: item.id}}" v-if="item">
    <div class="item mb-4 p-3 flex flex-column gap-2 md:gap-3 shadow-1">
      <div class="flex align-items-center gap-2 md:gap-3">
        <Avatar ref="avatar" :image="item.avatarUrl" class="mr-2 flex-shrink-0" size="xlarge" shape="circle"/>
        <div class="mr-2 flex-grow-1 flex flex-column md:flex-row md:align-items-center gap-2">
          <div class="flex-grow-1">
                    <span
                        class="font-family-tradegothic font-bold md:text-lg">{{ item.$data.lastname }} {{
                        item.$data.firstname
                      }}</span>
            <br>
            <div class="hidden gap-2 mt-2 sm:flex">
              <div v-for="(skill) in $skills" :key="skill.id"
                   v-tooltip.bottom="'C' + (skill.order +1) + ' - ' + skill.name" style="max-width:3rem; width:100%"
              >
                <router-link
                    :to="{name: 'trainee-skills', params: {id: item.id, skill: 'C' + (skill.order +1)}}"
                    @click.stop="(e)=> router.push(  {name: 'trainee-skills', params: {id: item.id, skill: 'C' + (skill.order +1)}})">
                  <SkillProgress :progress="skill.progress" class="flex-grow-1"/>
                </router-link>
              </div>
            </div>
          </div>
          <div class="" v-if="item.canEdit">
            <router-link :to="{name: 'trainee-employers', params: {id: item.id}}"
                         @click.stop="(e)=> router.push(  {name: 'trainee-employers', params: {id: item.id}} )" class="mr-2">
              <Chip icon="mdi mdi-domain" :label="''+item.$data.organizations_count"
                    style="background: var(--primary-color); color: var(--primary-color-text)"
                    v-tooltip.bottom="item.$data.organizations_count + ' employeur' + (item.$data.organizations_count>1 ? 's': '')"/>
            </router-link>

            <TraineeStatus :trainee="item" :icon="28"/>
          </div>
        </div>
        <div class="flex-grow-0 flex align-items-center gap-2">
          <div v-if="menuItems().length > 1">
            <Button icon="mdi mdi-dots-horizontal-circle-outline " class="p-button-xxl"
                    iconClass="text-3xl flex"
                    severity="info"
                    @click.stop.prevent="toggleMenu" rounded text/>

            <Menu ref="menu" id="overlay_menu" :model="menuItems()" :popup="true"/>
          </div>
          <div v-else-if="menuItems().length">
            <Button :icon="menuItems()[0].iconSingle || menuItems()[0].icon" :class="'p-button-xxl ' + menuItems()[0].class"
                    iconClass="text-3xl flex"
                    :severity="menuItems()[0].iconSeverity || 'info'"
                    @click.stop.prevent="menuItems()[0].command" rounded text/>
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-content-center gap-2 sm:hidden">
        <div v-for="(skill) in $skills" :key="skill.id"
             v-tooltip.bottom="'C' + (skill.order +1) + ' - ' + skill.name" class="flex-grow-1 w-1rem">
          <router-link
              :to="{name: 'trainee-skills', params: {id: item.id, skill: 'C' + (skill.order +1)}}"
              @click.stop="(e)=> router.push(  {name: 'trainee-skills', params: {id: item.id, skill: 'C' + (skill.order +1)}})">
            <SkillProgress :progress="skill.progress" class="flex-grow-1"/>
          </router-link>
        </div>
      </div>
    </div>
  </router-link>
</template>
