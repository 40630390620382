<script setup>
import {computed, inject, onBeforeMount, ref} from "vue";
import ProgressBar from "primevue/progressbar";
import Tag from "primevue/tag";
import SkillProgress from "@/components/Trainee/SkillProgress.vue";

import {useAuth} from "@/stores/auth";
import {useDialogs} from "@/composables/useDialogs";

const dialogRef = inject('dialogRef', null)

const authStore = useAuth()
const {dialogIndicatorValidation} = useDialogs()

const $skill = computed(() => {
    return dialogRef.value.data.trainee.skill(dialogRef.value.data.skill.id)
})
const $index = ref(null)
const $trainee = ref(null)

const skillProgress = (indicator) => {
    if (authStore.authUser.isCertified) {
      return indicator.validated ? 100 : (indicator.validation ? 50 : 0)
    } else {
      return indicator.validated ? 100 : 0
    }
}
const indicatorClass = () => {
    let classes = 'p-2';
    if (authStore.authUser.isCertified) {
        classes += " item cursor-pointer"
    }

    return classes
}

const dialogIndicator = (indicator, criteria, skill) => {
    if (!authStore.authUser.isCertified) return;
    dialogIndicatorValidation(indicator, criteria, skill, dialogRef.value.data.trainee)
}

onBeforeMount(() => {
    $skill.value = dialogRef.value.data.skill
    $trainee.value = dialogRef.value.data.trainee
    $index.value = $skill.value.order + 1
})
</script>
<template>
    <div>
        <ProgressBar :value="$skill.progress" :data-validated="$skill.progress === 100"
                     style="background-color: var(--surface-0)"></ProgressBar>
    </div>

    <div class="flex flex-column gap-4 mt-4">
        <div class="data-list-item p-2" v-for="(criteria, criteriaIndex) in $skill.children" :key="criteria.id">
            <div class="flex flex-column gap-2">
                <div class="mb-2 flex align-items-center gap-2">
                    <Tag :value="'C' + $index + '-' + (criteriaIndex+1)" class="font-family-tertiary text-base skill"/>
                    <span class="font-family-secondary font-bold ml-2 flex-grow-1">{{ criteria.name }}</span></div>
                <div>
                    <ProgressBar :value="criteria.progress" :data-validated="criteria.progress === 100"></ProgressBar>
                </div>
                <div class="data-list">
                    <div :class="indicatorClass()" v-for="indicator in criteria.children" :key="indicator.id"
                         @click="() => dialogIndicator(indicator, criteria, $skill)">
                        <div class="flex align-items-center gap-2">
                            <div class="w-2rem flex-shrink-0">
                                <SkillProgress :progress="skillProgress(indicator)"/>
                            </div>
                            <div>{{ indicator.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
