<script setup>
import {computed, inject, onBeforeMount, ref} from "vue";

import {useDate} from "@/composables/useDate";
import Button from "primevue/button";
import {useToast} from "primevue/usetoast";

const date = useDate()
const toast = useToast()

const dialogRef = inject('dialogRef', null)

const props = defineProps({
    trainee: {
        type: Object,
        default: null
    }
})

const $trainee = ref(null)
const processing = ref(false)
const setError = ref()
const canSubmit = ref(true)

const $verifiedAt = computed(() => {
    return date($trainee.value.$data.verified_at)
})

const closeDialog = () => {
    if (dialogRef.value) dialogRef.value.close($trainee)
}
const resend = async () => {
    const verified = $trainee.value.isEmailVerified ? false : true

    if (await $trainee.value.update({verified}, processing, setError)) {
        toast.add({severity: 'success', summary: 'Effectué.',
            detail: $trainee.value.isEmailVerified ? "Compte activé." : "Compte désactivé.",
            life: 5000});
        closeDialog()
    }
}

onBeforeMount(() => {
    $trainee.value = props.trainee || dialogRef.value.data.trainee
})

</script>

<template>

    <div>
        <div v-if="$trainee.isEmailVerified">
            <strong>{{ $trainee.$data.firstname }} {{ $trainee.$data.lastname }}</strong> a activé son adresse e-mail
            le {{ $verifiedAt.dayFirst() + $verifiedAt.format(" MMMM YYYY") }}
        </div>

        <div class="pb-4" v-else>
            <strong>{{ $trainee.$data.firstname }} {{ $trainee.$data.lastname }}</strong> n'a pas activé son adresse E-mail.
        </div>

        <div class="flex justify-content-between align-items-center mt-0">
            <Button
                label="Annuler"
                class="p-0"
                link
                @click="closeDialog"
                severity="secondary"
            />

            <Button type="button" @click="resend" icon="mdi mdi-email-fast-outline" :loading="processing"
                    loadingIcon="mdi mdi-loading mdi-spin" :disabled="!canSubmit"
                    label="Renvoyer l'e-mail d'activation" />
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
