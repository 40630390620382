<script setup>
import {computed, inject, onBeforeMount, ref} from "vue";

import {useDate} from "@/composables/useDate";
import Button from "primevue/button";
import {useToast} from "primevue/usetoast";

const date = useDate()
const toast = useToast()

const dialogRef = inject('dialogRef', null)

const props = defineProps({
    trainee: {
        type: Object,
        default: null
    }
})

const $trainee = ref(null)
const processing = ref(false)
const setError = ref()
const canSubmit = ref(true)

const $activatedAt = computed(() => {
    return date($trainee.value.$data.activated_at)
})

const $buttonLabel = computed(() => {
    return $trainee.value.isActive ? "Désactiver" : "Activer"
})

const $buttonSeverity = computed(() => {
    return $trainee.value.isActive ? "danger" : "success"
})
const $buttonIcon = computed(() => {
    return 'mdi mdi-' + ($trainee.value.isActive ? "alert-octagon-outline" : "check-circle-outline")
})

const closeDialog = () => {
    if (dialogRef.value) dialogRef.value.close($trainee)
}
const toggleStatus = async () => {
    const activated = $trainee.value.isActive ? false : true

    if (await $trainee.value.update({activated}, processing, setError)) {
        toast.add({severity: 'success', summary: 'Effectué.',
            detail: $trainee.value.isActive ? "Compte activé." : "Compte désactivé.",
            life: 5000});
        closeDialog()

    }

}

onBeforeMount(() => {
    $trainee.value = props.trainee || dialogRef.value.data.trainee
})

</script>

<template>

    <div class="pb-4 ">
        <div v-if="$trainee.isActive">
            Le compte stagiaire de <strong>{{ $trainee.$data.firstname }} {{ $trainee.$data.lastname }}</strong> est
            actif
            depuis le {{ $activatedAt.dayFirst() + $activatedAt.format(" MMMM YYYY") }}
        </div>

        <div v-else>
            Le compte stagiaire de <strong>{{ $trainee.$data.firstname }} {{ $trainee.$data.lastname }}</strong> est
            <strong>inactif</strong>.
        </div>
    </div>
    <div class="flex justify-content-between align-items-center mt-0">
        <Button
            label="Annuler"
            class="p-0"
            link
            @click="closeDialog"
            severity="secondary"
        />

        <Button type="button" @click="toggleStatus" :icon="$buttonIcon" :loading="processing"
                loadingIcon="mdi mdi-loading mdi-spin" :disabled="!canSubmit"
                :label="$buttonLabel" :severity="$buttonSeverity"/>
    </div>
</template>

<style scoped lang="scss">

</style>
