<script setup>
import {ref, inject, onBeforeMount, computed} from "vue";

import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";

import FormFieldError from "@/components/FormFieldError.vue";
import ImagePicker from "@/components/picker/Image.vue";

import {useDictionaries} from "@/stores/dictionaries";
import {useTrainees} from "@/stores/trainees";
import {useTrainee} from "@/models/useTrainee";
import {useImages} from "@/lib/images";
import {useToast} from 'primevue/usetoast';
import {useDate} from '@/composables/useDate';

/* Validation */
import ValidationErrors from "@/components/ValidationErrors.vue";

import {useVuelidate} from '@vuelidate/core'
import {/*required,*/ serverSide/*, required, email, phoneNC*/} from '@/lib/validators'
/* End Validation */

const dialogRef = inject('dialogRef', null)

const props = defineProps({
  item: {
    type: Object,
  }
})

const emit = defineEmits(['updated'])

const traineesStore = useTrainees()
const dictionariesStore = useDictionaries()
const date = useDate()
const toast = useToast();

const $item = ref(null)

const $loading = ref(true)
const $trainee = ref(null)

const form = ref({})
const $avatar = ref()

const processing = ref(false)
const setErrors = ref()

// Template Refs
const avatar = ref(null)
const avatarUploaded = async (image) => {
  const images = useImages()
  $avatar.value = image
  images.ratioResize($avatar)
  clearErrors()
}

const avatarUrl = computed(() => $avatar.value ? URL.createObjectURL($avatar.value) : $trainee.value.avatarUrl)

/**
 * Computed
 */
const errors = computed(() => {
  return setErrors.value ? setErrors.value.errors : null
})

const errorMessage = computed(() => {
  //if (setErrors.value && undefined !== setErrors.value.message) return setErrors.value.message
  if (setErrors.value?.errors.general && undefined !== setErrors.value?.errors.general) return setErrors.value?.errors.general
  if (v$.value.$invalid) return "Une ou plusieurs erreurs sont présentes dans le formulaire.\n" +
      "Merci de rectifier votre saisie."
  return null
})

const canSubmit = computed(() => {
  return v$.value.$invalid
})

const maxDate = ref(date().subtract(16, 'year'));

const closeDialog = () => {
  if (dialogRef.value) dialogRef.value.close($trainee)
}

const submit = async () => {
  clearErrors()
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return
  const data = {
    ...form.value,
    birthdate: date(form.value.birthdate).isValid() ? date(form.value.birthdate).format('YYYY-MM-DD') : '',
    avatar: '',
  }

  if ($avatar.value) {
    data.avatar = $avatar.value
  }

  const response = await $trainee.value.saveForm(data, processing, setErrors)
  if (response) {
    emit('updated', $trainee.value)
    toast.add({severity: 'success', summary: 'Effectué.', detail: response.message, life: 5000});
    closeDialog()
  }

  await v$.value.$validate()
}

const $city_options = ref([])
const $city_options_loading = ref(true)
const load_cities = () => {
  dictionariesStore.citiesDropdown().then((json) => {
    $city_options_loading.value = false
    $city_options.value = json
  })
}

/**
 * Form validation
 */
const rules = {
  avatar: {serverSide: serverSide({field: 'avatar', errors})},
  lastname: {/*required,*/ serverSide: serverSide({field: 'lastname', errors})},
  firstname: {/*required,*/ serverSide: serverSide({field: 'firstname', errors})},
  birthdate: {/*required,*/ serverSide: serverSide({field: 'birthdate', errors})},
  phone: {/*required, phoneNC,*/ serverSide: serverSide({field: 'phone', errors})},
  email: {/*required, email,*/ serverSide: serverSide({field: 'email', errors})},
  city: {/*required,*/ serverSide: serverSide({field: 'city', errors})},
  area: {/*required,*/ serverSide: serverSide({field: 'area', errors})},
}

const v$ = useVuelidate(rules, form, {$lazy: true})

const clearErrors = () => {
  return setErrors.value = null
}
/* End form validation */

onBeforeMount(async () => {
  load_cities()

  if (dialogRef.value.data && dialogRef.value.data) {
    $item.value = dialogRef.value.data.item
  } else {
    $item.value = props.item
  }

  if ($item.value) {
    traineesStore.getById($item.value.$data.id || $item.value).then((record) => {
      $trainee.value = record
      form.value = $trainee.value.formDataObject
      form.value.phone = $trainee.value.meta('phone')
      form.value.birthdate = date($trainee.value.meta('birthdate')).toDate()
      form.value.city = $trainee.value.meta('city')
      form.value.area = $trainee.value.meta('area')
      $loading.value = false
    })
  } else {
    $trainee.value = useTrainee()
    $loading.value = false
  }
})
</script>
<template>
  <div v-if="$loading" class="text-center">
    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
  </div>
  <form @submit.prevent="submit" class="flex flex-column gap-4" v-else>
    <div>
      <label class="p-label">Photo de profil</label>

      <div class="flex w-full gap-3 mt-3 mb-3 flex-md">
        <div>
          <Avatar ref="avatar" :image="avatarUrl" class="mr-2" size="xlarge" shape="circle"/>
        </div>
        <div>
          <ImagePicker choose-label="Nouvelle photo" @uploaded="avatarUploaded" avatar/>
          <FormFieldError :errors="v$.avatar.$error ? v$.avatar.$errors : [] " class="text-center mt-2"/>
          <small>Une image JPEG ou PNG, de moins de 2mo. Préférez un avatar carré.</small>
        </div>
      </div>
    </div>

    <div>
      <label for="lastname" class="p-label">Nom</label>
      <div class="p-inputgroup">
        <InputText
            id="lastname"
            v-model="form.lastname"
            type="text"
            class=""
            autocomplete="family-name"
            @blur="v$.lastname.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.lastname.$error }"/>
      </div>
      <FormFieldError :errors="v$.lastname.$error ? v$.lastname.$errors : [] "/>
    </div>
    <div>
      <label for="firstname" class="p-label">Prénom</label>
      <div class="p-inputgroup">
        <InputText
            id="firstname"
            v-model="form.firstname"
            type="text"
            class=""
            autocomplete="given-name"
            @blur="v$.firstname.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.firstname.$error }"/>
      </div>
      <FormFieldError :errors="v$.firstname.$error ? v$.firstname.$errors : [] "/>
    </div>
    <div>
                <span class="p-inputgroup">
                    <span class="p-float-label">
                        <Calendar
                            inputId="birthdate"
                            v-model="form.birthdate"
                            autocomplete="bday"
                            show-icon
                            touchUI
                            manualInput
                            :max-date="maxDate.toDate()"
                            @blur="v$.birthdate.$touch"
                            @focus="clearErrors"
                            :class="{ 'p-invalid': v$.birthdate.$error }"
                        />
                        <label for="birthdate">Date de naissance</label>
                    </span>
                </span>
      <FormFieldError :errors="v$.birthdate.$error ? v$.birthdate.$errors : [] "/>
    </div>
    <div>
      <div class="">
        <label for="phone" class="p-label">Numéro de téléphone</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="mdi mdi-phone-outline"></i></span>
          <InputMask
              id="phone"
              v-model="form.phone"
              type="phone"
              class=""
              autocomplete="tel-national"
              @blur="v$.phone.$touch"
              @focus="clearErrors"
              mask="99.99.99"
              inputmode="tel"
              :class="{ 'p-invalid': v$.phone.$error }"/>

        </div>
      </div>
      <FormFieldError :errors="v$.phone.$error ? v$.phone.$errors : [] "/>
    </div>
    <div>
      <label for="email" class="p-label">E-mail</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="mdi mdi-email-outline"></i></span>
        <InputText
            id="email"
            v-model="form.email"
            type="email"
            class=""
            autocomplete="email"
            @blur="v$.email.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.email.$error }"/>
      </div>
      <FormFieldError :errors="v$.email.$error ? v$.email.$errors : [] "/>
      <div v-if="$item && $item.$$('pending_email')">
        <small><br>Un changement d'e-mail pour
          <i>{{ $item.$$('pending_email') }}</i> est en attente de validation.</small>
      </div>
    </div>
    <div>
      <label for="city" class="p-label">Commune de résidence</label>
      <div class="p-inputgroup">
        <Dropdown v-model="form.city" inputId="city" :options="$city_options" option-label="label"
                  option-value="value"
                  class="w-full" :loading="$city_options_loading" filter
                  @blur="v$.city.$touch"
                  @focus="clearErrors"
                  :class="{ 'p-invalid': v$.city.$error }"
        />
      </div>
      <FormFieldError :errors="v$.city.$error ? v$.city.$errors : [] "/>
    </div>
    <div>
      <label for="area" class="p-label">Quartier / Tribu</label>
      <div class="p-inputgroup">
        <InputText
            id="area"
            v-model="form.area"
            type="text"
            class=""
            @blur="v$.area.$touch"
            @focus="clearErrors"
            :class="{ 'p-invalid': v$.area.$error }"/>
      </div>
      <FormFieldError :errors="v$.area.$error ? v$.area.$errors : [] "/>
    </div>

    <ValidationErrors class="mb-0 w-full" :error="errorMessage"/>

    <div class="flex justify-content-between align-items-center mt-0">
      <Button
          label="Annuler"
          class="p-0"
          link
          @click="closeDialog"
          severity="secondary"
      />

      <Button type="submit" icon="mdi mdi-login" :loading="processing" :disabled="canSubmit"
              label="Enregistrer"/>
    </div>

  </form>
</template>
