<script setup>
import {ref, inject, onBeforeMount, computed} from "vue";

import Button from "primevue/button";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import InputSwitch from 'primevue/inputswitch';

import FormFieldError from "@/components/FormFieldError.vue";

import {useToast} from 'primevue/usetoast';
import {useDate} from '@/composables/useDate';

/* Validation */
import ValidationErrors from "@/components/ValidationErrors.vue";

import {useVuelidate} from '@vuelidate/core'
import {required, serverSide} from '@/lib/validators'
/* End Validation */

const dialogRef = inject('dialogRef', null)

const props = defineProps({
    trainee: {
        type: Object,
    },
    item: {
        type: Object,
    },

})

const emit = defineEmits(['updated'])

const date = useDate()
const toast = useToast();

const $item = ref(null)

const $loading = ref(true)
const $trainee = ref(null)

const form = ref({
    name: '',
    acquired_at: '',
    verified: false,
})

const processing = ref(false)
const setErrors = ref()

/**
 * Computed
 */
const errors = computed(() => {
    return setErrors.value ? setErrors.value.errors : null
})

const errorMessage = computed(() => {
    //if (setErrors.value && undefined !== setErrors.value.message) return setErrors.value.message
    if (setErrors.value?.errors.general && undefined !== setErrors.value?.errors.general) return setErrors.value?.errors.general
    if (v$.value.$invalid) return "Une ou plusieurs erreurs sont présentes dans le formulaire.\n" +
        "Merci de rectifier votre saisie."
    return null
})

const canSubmit = computed(() => {
    return v$.value.$invalid
})

const maxDateAcquired_At = ref(date())

const closeDialog = () => {
    if (dialogRef.value) dialogRef.value.close($trainee)
}

const submit = async () => {
    clearErrors()
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) return

    processing.value = true

    const data = {
        ...form.value,
        user_id: $trainee.value.id,
        acquired_at: date(form.value.acquired_at).isValid() ? date(form.value.acquired_at).format('YYYY-MM-DD') : '',
    }
    if ($item.value) {
        data.id = $item.value.id
    }

    const i = await $trainee.value.updateQualification(data, processing, setErrors)
    if (i) {



        emit('updated', $trainee)
        toast.add({
            severity: 'success', summary: 'Effectué.',
            detail: 'Formation ou diplôme ' + ($item.value ? 'modifié' : 'ajouté') + ' avec succès', life: 3000
        });

        processing.value = false
        closeDialog()
    }

    await v$.value.$validate()
}

/**
 * Form validation
 */
const rules = {
    name: {required,serverSide: serverSide({field: 'name', errors})},
    acquired_at: {required, serverSide: serverSide({field: 'acquired_at', errors})},
    verified: {/*required,*/ serverSide: serverSide({field: 'verified', errors})},
}

const v$ = useVuelidate(rules, form, {$lazy: true})

const clearErrors = () => {
    return setErrors.value = null
}
/* End form validation */

onBeforeMount(async () => {
    if (dialogRef.value.data) {
        $item.value = dialogRef.value.data.item
        $trainee.value = dialogRef.value.data.trainee
    } else {
        $item.value = props.item
        $trainee.value = props.trainee
    }
    if ($item.value) {
        form.value = {
            name: $item.value.name,
            acquired_at: date($item.value.acquired_at).toDate(),
            verified: !!$item.value.verified_at,
        }
    }

    $loading.value = false
})
</script>
<template>
    <div v-if="$loading" class="text-center">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
    </div>
    <form @submit.prevent="submit" class="flex flex-column gap-4" v-else>
        <div>
            <label for="name" class="p-label">Intitulé de la formation ou du diplôme</label>
            <div class="p-inputgroup">
                <InputText
                    id="name"
                    v-model="form.name"
                    type="text"
                    class=""
                    autocomplete="family-name"
                    @blur="v$.name.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.name.$error }"/>
            </div>
            <FormFieldError :errors="v$.name.$error ? v$.name.$errors : [] "/>
        </div>

        <div>

            <label for="acquired_at" class="p-label">Date d'obtention</label>

            <div class="p-inputgroup">
                <Calendar
                    inputId="acquired_at"
                    v-model="form.acquired_at"
                    autocomplete="bday"
                    dateFormat="mm/yy"
                    view="month"
                    show-icon
                    touchUI
                    manualInput
                    :max-date="maxDateAcquired_At.toDate()"
                    @change="v$.acquired_at.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.acquired_at.$error }"
                />
            </div>
            <FormFieldError :errors="v$.acquired_at.$error ? v$.acquired_at.$errors : [] "/>
        </div>

        <div>

            <label for="verified" class="p-label">Visé</label>

            <div class="p-inputgroup">
                <InputSwitch
                    id="verified"
                    v-model="form.verified"
                    @blur="v$.verified.$touch"
                    @focus="clearErrors"
                    :class="{ 'p-invalid': v$.verified.$error }"
                />
            </div>
            <FormFieldError :errors="v$.verified.$error ? v$.verified.$errors : [] "/>
        </div>

        <ValidationErrors class="mb-0 w-full" :error="errorMessage"/>

        <div class="flex justify-content-between align-items-center mt-0">
            <Button
                label="Annuler"
                class="p-0"
                link
                @click="closeDialog"
                severity="secondary"
            >

            </Button>

            <Button type="submit" icon="mdi mdi-login" :loading="processing" :disabled="canSubmit"
                    label="Enregistrer"/>
        </div>

    </form>
</template>
