<script setup>
import {inject, onBeforeMount, ref, computed} from "vue";

import Button from 'primevue/button'
import InputSwitch from "primevue/inputswitch";
import {useAuth} from "@/stores/auth";
import {useUsers} from "@/stores/users";
import ProgressSpinner from "primevue/progressspinner";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiHelpRhombus, mdiCrownCircleOutline, mdiStarCircleOutline} from "@mdi/js";

import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import {useToast} from "primevue/usetoast";

const dialogRef = inject('dialogRef', null)
const dynamicDialogDefaults = inject('dynamicDialogDefaults')

const authStore = useAuth()
const toast = useToast()

const props = defineProps({
    item: {
        type: Object,
    }
})

const typeOptions = ref([
    {name: 'Professionnel', value: 'professional'},
    {name: 'Admin', value: 'admin', icon: mdiStarCircleOutline},
    {name: 'Super Admin', value: 'super-admin', icon: mdiCrownCircleOutline},
])

const usersStore = useUsers();

const $item = dialogRef.value.data.item || props.item || null
const $user = ref(null)
const $loading = ref(true)

const $processing = ref(false)
const $errors = ref(null)

const $emailActivated = ref(false)

const $activated = ref(false)

const $accountType = ref(null)
const $accountTypeDialogDisplay = ref(false)
const $processingAccountType = ref(false)
const $accountErrors = ref()

const $superAdmin = ref(false)
const $admin = ref(false)
const $organizationAdmin = ref(false)
const $certified = ref(false)


const setFlags = () => {
    $emailActivated.value = $item.isEmailVerified
    $activated.value = $item.isActive

    $superAdmin.value = $item.isSuperAdmin
    $admin.value = $item.isAdmin || $item.isSuperAdmin

    $accountType.value = ($item.isSuperAdmin ? 'super-admin' : ($item.isAdmin ? 'admin' : 'professional'))

    $organizationAdmin.value = $item.isSuperAdmin || $item.isProfessionalAdmin

    if ($item.isSuperAdmin || $item.isAdmin) {
        $certified.value = true
    } else if ($item.$data.organization && $item.$data.organization.certified) {
        if ($item.isProfessionalAdmin || $item.isProfessionalCertified) {
            $certified.value = true
        } else {
            $certified.value = false
        }
    }
}

const accountTypeDisabled = computed(() => {
    if (!authStore.authUser.hasRole('super-admin') || authStore.authUser.id === $item.id) {
        return true;
    }
    return $processingAccountType.value
})

const allDisabled = computed(() => {
    if (!$item.canEdit) return true;
    return $processing.value;
})
const activatedDisabled = computed(() => {
    if (allDisabled.value) return true;
    return false
})
const organizationAdminDisabled = computed(() => {
    if (allDisabled.value) return true;
    if ($item.isSuperAdmin) {
        return true;
    }
    return false
})
const certifiedDisabled = computed(() => {
    if (allDisabled.value) return true;
    if ($item.isSuperAdmin) {
        return true;
    }
    if ($item.isProfessionalAdmin && $item.organization.isCertified) {
        return true;
    }
    return false
})

const setAccountActive = async () => {
    $errors.value = null

    const $value = $activated.value ?  false : true
    await $user.value.update({activated: $value}, $processing, $errors)
    if (!$accountErrors.value) {
        showToast( $activated.value ? "Utilisateur activé." : "Utilisateur désactivé.");
        setFlags()
    } else {
        showToast( $errors.value.message, 'error');
    }
}

const setAccountType = async () => {
    $accountErrors.value = null

    await $user.value.update({account_type: $accountType.value}, $processingAccountType, $accountErrors)
    if (!$accountErrors.value) {
        showToast( "Type de compte modifié.");
        setFlags()
    } else {
        showToast( $accountErrors.value.message, 'error');
    }
}

const setAccountCertified = async () => {
    $errors.value = null

    const $value = $certified.value ? false : true
    await $user.value.update({certified: $value}, $processing, $errors)
    if (!$accountErrors.value) {
        showToast($certified.value ? "L'utilisateur est maintenant certifié." : "L'utilisateur n'est plus certifié.");
        setFlags()
    } else {
        showToast( $errors.value.message, 'error');
    }
}

const setAccountOrgAdmin = async () => {
    $errors.value = null

    const $value = $organizationAdmin.value ? false : true
    await $user.value.update({org_admin: $value}, $processing, $errors)
    if (!$accountErrors.value) {
        showToast( $organizationAdmin.value ? "L'utilisateur est maintenant administrateur de son organisme." : "L'utilisateur n'est plus administrateur de son organisme.")
        setFlags()
    } else {
        showToast( $errors.value.message, 'error');
    }
}

const showToast=(detail, severity='success') => {
    toast.add({
        severity,
        summary: 'Effectué.',
        detail,
        life: 5000
    });
}

onBeforeMount(async () => {
    if ($item) {
        usersStore.getById($item.$data.id || $item, true).then((record) => {
            $user.value = record
            setFlags()
            $loading.value = false
        }).catch(() => {
            $user.value = false
            $loading.value = false
        })
    } else {
        $user.value = false
        $loading.value = false
    }
})

</script>
<template>
    <div v-if="$loading" class="text-center">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
    </div>
    <div v-else-if="$user" class="flex flex-column gap-4">
        <div>
            <div class="flex align-items-center" v-if="$user.meta('phone')">
                <div><i class="mdi mdi-phone-outline mr-2 text-xl p-text-primary-color"/></div>
                <div>
                    <div><a :href="`tel: +687 ${$user.meta('phone')}`">{{ $user.meta('phone') }}</a></div>
                </div>
            </div>
            <div class="flex align-items-center" v-if="$user.$$('email')">
                <div><i class="mdi mdi-email-outline mr-2 text-xl p-text-primary-color"/></div>
                <div class="pb-1">
                    <a :href="`mailto: ${$user.$$('display_name')} <${$user.$$('email')}>`">{{ $user.$$('email') }}</a>
                </div>
            </div>
        </div>
        <div class="flex gap-2">
            <a :href="`tel: +687 ${$user.meta('phone')}`" v-if="$user.meta('phone')">
                <Button label="Appeler" icon="mdi mdi-phone" severity="reverse"/>
            </a>
            <a :href="`mailto: ${$user.$$('display_name')} <${$user.$$('email')}>`">
                <Button label="Envoyer un E-mail" icon="mdi mdi-email"/>
            </a>
        </div>
        <div v-if="authStore.authUser.hasRole('super-admin') || authStore.authUser.hasRole('admin')">
            <label>Type de compte
                <Button @click="()=>{$accountTypeDialogDisplay=true}" class="p-0" severity="secondary" text
                        rounded><span
                    class="inline-block vertical-align-middle"><svg-icon
                    type="mdi" :path="mdiHelpRhombus"
                    :size="20" class="block"/></span></Button>
            </label>
            <div v-if="$accountType.value">{{ $accountType.value }}</div>
            <div class="flex align-items-center gap-2">
                <SelectButton v-model="$accountType" :options="typeOptions" class="mt-3"
                              :pt="{button: {class: 'text-sm px-1 py-2 sm:text-base sm:px-3'}}" optionLabel="name"
                              option-value="value"
                              aria-labelledby="basic" :allow-empty="false"
                              :disabled="accountTypeDisabled" @click.prevent="setAccountType">
                    <template #option="slotProps">
                        <div class="flex align-items-center gap-1">
                    <span v-if="slotProps.option.icon"
                          class="inline-block"><svg-icon type="mdi"
                                                         :path="slotProps.option.icon"
                                                         :size="16"
                                                         class="block"></svg-icon></span>
                            <span class="p-button-label mt-1" data-pc-section="label">{{ slotProps.option.name }}</span>
                        </div>
                    </template>
                </SelectButton>
            </div>
        </div>
        <div class="flex flex-column md:flex-row md:jus gap-2 mt-2" v-if="! authStore.authUser.hasRole('trainee')">
            <div class="flex flex-column gap-2 mt-2 w-full md:w-6">
                <label class="">Statut</label>
                <div class="flex align-items-center gap-2">
                    <InputSwitch id="emailActivated" v-model="$emailActivated" :disabled="true"
                                 class="flex-shrink-0"/>
                    <label for="emailActivated">
                        E-mail activé
                    </label>
                </div>
                <div class="flex align-items-center gap-2">
                    <InputSwitch id="activated" v-model="$activated" :disabled="activatedDisabled"
                                 class="flex-shrink-0" @click.prevent="setAccountActive"/>
                    <label for="activated">Actif</label>
                </div>
            </div>
            <div class="flex flex-column gap-2 mt-2 w-full md:w-6 md:mt-0">
                <label class="mt-2">Permissions</label>
                <div class="flex align-items-center gap-2">
                    <InputSwitch id="organizationAdmin" v-model="$organizationAdmin"
                                 :disabled="organizationAdminDisabled"
                                 class="flex-shrink-0" @click.prevent="setAccountOrgAdmin"/>
                    <label for="organizationAdmin">
                        Administrateur de son organisme</label>
                </div>
                <div class="flex align-items-center gap-2" v-if="$user.isProfessional && $user.organization.isCertified">
                    <InputSwitch id="certified" v-model="$certified" :disabled="certifiedDisabled"
                                 class="flex-shrink-0" @click.prevent.stop="setAccountCertified"/>
                    <label for="certified">
                        Certifié
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        Utilisateur non spécifié
    </div>
    <Dialog v-model:visible="$accountTypeDialogDisplay" modal header="Aide: type de compte" dismissable-mask
            :style="dynamicDialogDefaults.props.style" :breakpoints="dynamicDialogDefaults.props.breakpoints">
        <p><strong>Super Administrateur :</strong> Un super administrateur à tous les droits sur les utilisateurs,
            stagiaires, organizations et paramètres de l'application.</p>
        <p><strong>Administrateur :</strong> Un administrateur à les même droits qu'un super-administrateur avec des
            restrictions :
            <ul>
                <li>Il ne peut pas changer le type de compte d'un utilisateur</li>
                <li>Il ne peut pas modifier les paramètres de l'application.</li>
                <li>Il ne peut pas modifier les utilisateurs de son organisme, sauf s'il est promu administrateur de son
                    organisme.
                </li>
            </ul>
        </p>
        <p><strong>Professionnel :</strong> Un professionnel peut ajouter des stagiaires à son organisme et les
            consulter. Il peut consulter les informations de son organismes et la listes des utilisateurs. Il peut être
            promu administrateur de son organisme et peut être certifié si son organisme est préalablement certifié.</p>
    </Dialog>
</template>
