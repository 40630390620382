<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";

import {useAuth} from "@/stores/auth";
import {useDate} from "@/composables/useDate";
import {useRouter} from 'vue-router'


import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import BlockUI from "primevue/blockui";
import QrcodeVue from 'qrcode.vue'

import SvgIcon from '@jamescoyle/vue-icon'
import {mdiCellphoneRemove} from '@mdi/js'
import {useToast} from "primevue/usetoast";

const authStore = useAuth()
const dayjs = useDate()
const router = useRouter()
const toast = useToast()

const $loading = ref(true)
const $printableLoading = ref(false)

const $accessToken = ref(null)
const $expiresTimer = ref(null)

let refreshTimer = null

const expires_at = computed(() => {
    if (!$accessToken.value) {
        return ''
    }

    return dayjs($accessToken.value.expires_at)
})

const tokenUri = computed(() => {
    if (!$accessToken.value) {
        return ''
    }

    const uri = new URL(`/p/`, location.href).href

    return uri
})

const tokenUrl = computed(() => {
    return tokenUri.value + $accessToken.value.token
})

const copyTokenUrl = () => {
    navigator.clipboard.writeText(tokenUrl.value)
    toast.add({severity: 'success', summary: 'Copié !', detail: 'Le lien alternative a été copié dans votre presse-papier.', life: 3000});

}

const shareData = computed(() => {
    return {
        title: "Mon passeport B.E.E.",
        text: "Utilisez ce lien pour accéder à mon passport B.E.E.",
        url: tokenUrl.value,
    }
})
const canBrowserShareData = () => {
    if (!navigator.share || !navigator.canShare) {
        return false;
    }

    return navigator.canShare(shareData.value);
}

const share = async () => {
    await navigator.share(shareData.value);
}

const loadAccessToken = async () => {

    if(! authStore.authUser) return

    if (refreshTimer) {
        clearInterval(refreshTimer)
        refreshTimer = null
    }

    $loading.value = true
    $accessToken.value = await authStore.authUser.newAccessToken()
    $expiresTimer.value = expires_at.value.fromNow()

    refreshTimer = setInterval(async () => {
        if (expires_at.value.isBefore(dayjs())) {
            await loadAccessToken()
        } else {
            $expiresTimer.value = expires_at.value.fromNow()
        }
    }, 1000)


    $loading.value = false

}

const print = async () => {
    const routeData = router.resolve({name: 'access-token-print'});
    window.open(routeData.href, '_blank');
}

onMounted(async () => {
    await loadAccessToken()
    document.addEventListener('visibilitychange', function() {
        if(document.hidden) {
            if (refreshTimer) {
                clearInterval(refreshTimer)
                refreshTimer = null
            }
        }
        else {
            loadAccessToken()
        }
    });
})

onUnmounted(()=>{
    if (refreshTimer) {
        clearInterval(refreshTimer)
        refreshTimer = null
    }
})
</script>

<template>
    <div v-if="$loading && ! $accessToken" class="text-center">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="5"></ProgressSpinner>
    </div>
    <div v-else class="flex justify-content-center">
        <div style="max-width: 500px">
            <div class="data-list-item p-3">
                <p class="mt-0">Pour <strong>donner accès à votre passeport B.E.E. à un employeur</strong>, présentez lui ce QR code
                    à
                    usage
                    unique.</p>
                <p>Vous pourrez révoquer cet accès à tout moment.</p>
                <BlockUI :blocked="$loading">
                    <div class="flex align-items-center justify-content-center p-2">
                        <QrcodeVue :value="tokenUrl" :margin="2" :size="250"/>
                    </div>
                    <div class="text-center text-sm">
                        <strong class="font-family-secondary">Lien URl :</strong> {{ tokenUri }}<strong>{{ $accessToken.token }}</strong> <i
                        class="mdi mdi-clipboard-outline cursor-pointer hover:text-primary" @click="copyTokenUrl"/>
                    </div>
                </BlockUI>
            </div>

            <div class="text-center mt-2 text-sm">
                <span v-if="!$loading">Expire {{ $expiresTimer }}</span>
                <span v-else><i class="mdi mdi-loading mdi-spin"/>Rafraichissement...</span>
            </div>
            <div class="text-center mt-4" v-if="canBrowserShareData()">
                <Button label="Partager" icon="mdi mdi-share-variant" @click="share"/>
            </div>

            <div class="data-list-item p-2 mt-4" :style="{background: 'var(--primary-200)'}">
                <div class="flex gap-2">
                    <div>
                        <SvgIcon size="48" type="mdi" :path="mdiCellphoneRemove"/>
                    </div>
                    <div class="flex-grow-1">Vous n'avez pas de smartphone ? Imprimez un QR code à usage multiple.</div>
                </div>
                <div class="flex justify-content-end mt-3">
                    <Button label="Imprimer" outlined severity="secondary" size="small"
                            :icon="'mdi mdi-' + ($printableLoading ? 'loading mdi-spin' : 'printer-outline')"
                            @click="print"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
