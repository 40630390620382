<script setup>
import Navigation from "@/components/Navigation.vue";
import {useAuth} from "@/stores/auth";

const items = {
    passport: {
        label: 'Passeport',
        icon: 'mdi mdi-passport',
        to: {name: 'passport'},
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
    organisations: {
        label: "Employeurs",
        to: {name: 'employers'},
        icon: 'mdi mdi-domain',
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
    profile: {
        label: "Profil",
        to: {name: 'profile'},
        icon: 'mdi mdi-account-settings',
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
    settings: {
        label: "Réglages",
        to: {name: 'settings'},
        icon: 'mdi mdi-cog',
        disabled: function () {
            if(useAuth().authUser && useAuth().authUser.canNavigate) {
                return false
            }
            return true
        }
    },
}
</script>
<template>
    <Navigation :items="items"></Navigation>
</template>
