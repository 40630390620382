<script setup>

import {computed} from "vue";

const props = defineProps(
    {
      size: {
        default: 90,
        type: Number,
      }
    }
)

const $size = computed(() => props.size + 'pt')

</script>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="isolation:isolate"
       viewBox="222.212 869.276 93.448 103.604" :width="$size">
    <g>
      <g>
        <path
            d=" M 263.786 869.276 L 274.018 875.183 L 284.25 881.09 L 284.25 892.905 L 284.25 904.72 L 274.018 910.627 L 263.786 916.534 L 253.555 910.627 L 243.323 904.72 L 243.323 892.905 L 243.323 881.09 L 253.555 875.183 L 263.786 869.276 Z "
            fill-rule="evenodd" fill="rgb(248,190,59)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.7;1"
                   begin="-0.85s"></animate>
        </path>
        <path
            d=" M 263.786 879.204 L 269.719 882.63 L 275.652 886.054 L 275.652 892.905 L 275.652 899.755 L 269.719 903.181 L 263.786 906.606 L 257.854 903.181 L 251.921 899.755 L 251.921 892.905 L 251.921 886.054 L 257.854 882.63 L 263.786 879.204 Z "
            fill-rule="evenodd" fill="rgb(255,206,75)"/>
        <path
            d=" M 263.786 884.775 L 267.307 886.807 L 270.828 888.84 L 270.828 892.905 L 270.828 896.97 L 267.307 899.003 L 263.786 901.035 L 260.266 899.003 L 256.746 896.97 L 256.746 892.905 L 256.746 888.84 L 260.266 886.807 L 263.786 884.775 Z "
            fill-rule="evenodd" fill="rgb(252,226,166)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.5;1"
                   begin="-0.25s"></animate>
        </path>
      </g>
      <g>
        <path
            d=" M 242.676 906.855 L 252.907 912.763 L 263.139 918.67 L 263.139 930.484 L 263.139 942.299 L 252.907 948.206 L 242.676 954.114 L 232.444 948.206 L 222.212 942.299 L 222.212 930.484 L 222.212 918.67 L 232.444 912.763 L 242.676 906.855 Z "
            fill-rule="evenodd" fill="rgb(248,190,59)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.7;1"
                   begin="-0.34s"></animate>
        </path>
        <path
            d=" M 242.676 916.783 L 248.608 920.209 L 254.541 923.634 L 254.541 930.484 L 254.541 937.334 L 248.608 940.76 L 242.676 944.185 L 236.743 940.76 L 230.81 937.334 L 230.81 930.484 L 230.81 923.634 L 236.743 920.209 L 242.676 916.783 Z "
            fill-rule="evenodd" fill="rgb(255,206,75)">
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.5;1"
                 begin="0s"></animate>
        </path>
        <path
            d=" M 242.676 922.354 L 246.196 924.387 L 249.716 926.419 L 249.716 930.484 L 249.716 934.55 L 246.196 936.582 L 242.676 938.614 L 239.155 936.582 L 235.635 934.55 L 235.635 930.484 L 235.635 926.419 L 239.155 924.387 L 242.676 922.354 Z "
            fill-rule="evenodd" fill="rgb(252,226,166)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.6;1"
                   begin="-0.81s"></animate>
        </path>
      </g>
      <g>
        <path
            d=" M 286.348 906.855 L 296.58 912.763 L 306.811 918.67 L 306.811 930.484 L 306.811 942.299 L 296.58 948.206 L 286.348 954.114 L 276.116 948.206 L 265.885 942.299 L 265.885 930.484 L 265.885 918.67 L 276.116 912.763 L 286.348 906.855 Z "
            fill-rule="evenodd" fill="rgb(248,190,59)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.5;1"
                   begin="-0.18s"></animate>
        </path>
        <path
            d=" M 286.348 916.783 L 292.28 920.209 L 298.213 923.634 L 298.213 930.484 L 298.213 937.334 L 292.28 940.76 L 286.348 944.185 L 280.415 940.76 L 274.483 937.334 L 274.483 930.484 L 274.483 923.634 L 280.415 920.209 L 286.348 916.783 Z "
            fill-rule="evenodd" fill="rgb(255,206,75)">
        </path>
        <path
            d=" M 286.348 922.354 L 289.868 924.387 L 293.389 926.419 L 293.389 930.484 L 293.389 934.55 L 289.868 936.582 L 286.348 938.614 L 282.827 936.582 L 279.307 934.55 L 279.307 930.484 L 279.307 926.419 L 282.827 924.387 L 286.348 922.354 Z "
            fill-rule="evenodd" fill="rgb(252,226,166)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.5;1"
                   begin="-0.65s"></animate>
        </path>
      </g>
      <g>
        <path
            d=" M 304.484 947.07 L 310.072 950.296 L 315.66 953.523 L 315.66 959.975 L 315.66 966.427 L 310.072 969.654 L 304.484 972.88 L 298.896 969.654 L 293.308 966.427 L 293.308 959.975 L 293.308 953.523 L 298.896 950.296 L 304.484 947.07 Z "
            fill-rule="evenodd" fill="rgb(248,190,59)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.5;1"
                   begin="-0.50s"></animate>
        </path>
        <path
            d=" M 304.701 953.35 L 307.78 955.128 L 310.86 956.905 L 310.86 960.461 L 310.86 964.016 L 307.78 965.794 L 304.701 967.571 L 301.622 965.794 L 298.543 964.016 L 298.543 960.461 L 298.543 956.905 L 301.622 955.128 L 304.701 953.35 Z "
            fill-rule="evenodd" fill="rgb(255,206,75)"/>
        <path
            d=" M 304.914 956.26 L 306.811 957.355 L 308.708 958.451 L 308.708 960.642 L 308.708 962.833 L 306.811 963.929 L 304.914 965.024 L 303.016 963.929 L 301.118 962.833 L 301.118 960.642 L 301.118 958.451 L 303.016 957.355 L 304.914 956.26 Z "
            fill-rule="evenodd" fill="rgb(252,226,166)"/>
      </g>
      <g>
        <path
            d=" M 301.053 880.909 L 307.743 884.771 L 314.433 888.633 L 314.433 896.359 L 314.433 904.083 L 307.743 907.946 L 301.053 911.808 L 294.363 907.946 L 287.673 904.083 L 287.673 896.359 L 287.673 888.633 L 294.363 884.771 L 301.053 880.909 Z "
            fill-rule="evenodd" fill="rgb(248,190,59)">
          <animate attributeName="opacity" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="1;0.5;1"
                   begin="-0.35s"></animate>
        </path>
        <path
            d=" M 300.895 887.338 L 305 889.708 L 309.106 892.079 L 309.106 896.82 L 309.106 901.56 L 305 903.93 L 300.895 906.3 L 296.79 903.93 L 292.684 901.56 L 292.684 896.82 L 292.684 892.079 L 296.79 889.708 L 300.895 887.338 Z "
            fill-rule="evenodd" fill="rgb(255,206,75)"/>
        <path
            d=" M 300.895 891.666 L 303.127 892.954 L 305.358 894.242 L 305.358 896.82 L 305.358 899.396 L 303.127 900.685 L 300.895 901.973 L 298.663 900.685 L 296.431 899.396 L 296.431 896.82 L 296.431 894.242 L 298.663 892.954 L 300.895 891.666 Z "
            fill-rule="evenodd" fill="rgb(252,226,166)"/>
      </g>
    </g>
  </svg>

</template>
<style lang="scss" scoped>
svg {
  animation: cssAnimation 0s 0.5s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

</style>
